@import './tailwind.css';
@import './fonts.css';

html {
  scroll-behavior: smooth;
  font-size:14px;
}
@media (max-width: 1500px) and (min-width:1024px){
  html{
    font-size:12px;
  }
}

body {
  margin: 0;
  font-family: 'Axiforma-Book', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

/*Accordion Styles*/
.accordion__button:before{
  content: "";
  display: none;
}
.image-user{
  min-height: 1.75rem;
  min-width: 1.75rem;
}
.mobile-none-func{
  width: 100%;
  height: 100%;
  overflow: hidden;
}