/*Colors*/
:root {
    --primary-50:#e0dae8;
    --primary-100:#cbc1d9;
    --primary-200:#b2a2c7;
    --primary-300:#9883b4;
    --primary-400:#7e64a1;
    --primary:#64458e;
    --primary-600:#533a76;
    --primary-700:#432e5f;
    --primary-800:#322347;
    --primary-900:#21172f;
    --primary-950:#140e1c;
    --primary-contrast:#fff;
  
    --secondary-50: #cdd2d9;
    --secondary-100: #abb4c0;
    --secondary-200: #828fa1;
    --secondary-300: #586981;
    --secondary-400: #2e4462;
    --secondary: #041e42;
    --secondary-600: #031937;
    --secondary-700: #03142c;
    --secondary-800: #020f21;
    --secondary-900: #010a16;
    --secondary-950: #01060d;
    --secondary-contrast: #fff;
  
    --tertiary-50: #ecd2e3;
    --tertiary-100: #dfb4d1;
    --tertiary-200: #cf8eba;
    --tertiary-300: #be68a3;
    --tertiary-400: #ae438c;
    --tertiary: #9e1d75;
    --tertiary-600: #841862;
    --tertiary-700: #69134e;
    --tertiary-800: #4f0f3b;
    --tertiary-900: #350a27; 
    --tertiary-950: #200617;
    --tertiary-contrast: #fff;
  
    --quaternary-50: #e9f6f6;
    --quaternary-100: #daefef;
    --quaternary-200: #DAEFEF;
    --quaternary-300: #C8E7E7;
    --quaternary-400: #a3d8d8;
    --quaternary: #91d0d0;
    --quaternary-600: #79adad;
    --quaternary-700: #618b8b;
    --quaternary-800: #496868;
    --quaternary-900: #304545; 
    --quaternary-950: #1d2a2a;
    --quaternary-contrast: #fff;
  
    --neutral-50: #F8F9FA;
    --neutral-100: #daefef;
    --neutral-200: #F8F9FA;
    --neutral-300: #F3F5F7;
    --neutral-400: #ECEFF3;
    --neutral: #E6EAF0 ;
    --neutral-600: #DAE0E8;
    --neutral-700: #B6BBC1;
    --neutral-800: #6D7074;
    --neutral-900: #494B4D;
    --neutral-950: #2C2D2E;
    --neutral-contrast: #fff;
  
    --error-50: var(--tertiary-50);
    --error-100: var(--tertiary-100);
    --error-200: var(--tertiary-200);
    --error-300: var(--tertiary-300);
    --error-400: var(--tertiary-400);
    --error: var(--tertiary);
    --error-600: var(--tertiary-600);
    --error-700: var(--tertiary-700);
    --error-800: var(--tertiary-800);
    --error-900: var(--tertiary-900);
    --error-950: var(--tertiary-950);
    --error-contrast: var(--tertiary-contrast);
  
    --success-50: var(--quaternary-50);
    --success-100: var(--quaternary-100);
    --success-200: var(--quaternary-200);
    --success-300: var(--quaternary-300);
    --success-400: var(--quaternary-400);
    --success: var(--quaternary); 
    --success-600: var(--quaternary-600);
    --success-700: var(--quaternary-700);
    --success-800: var(--quaternary-800);
    --success-900: var(--quaternary-900);
    --success-950:var(--quaternary-950);
    --success-contrast: var(--quaternary-contrast);
  
  
    --warning-50: #FFEACC;
    --warning-100: #FFDCAA;
    --warning-200: #FFCA80;
    --warning-300: #FFB855;
    --warning-400: #FFA72B;
    --warning: #FF9500;
    --warning-600: #D47C00;
    --warning-700: #AA6300;
    --warning-800: #804B00;
    --warning-900: #553200; 
    --warning-950: #331E00;
    --warning-contrast: #fff;
  
    --info-50: #D6EFF4;
    --info-100: #BAE5EC;
    --info-200: #98d8e3;
    --info-300: #97D7E3;
    --info-400: #75CADA;
    --info: #30b0c7;
    --info-600: #2893A6;
    --info-700: #207585;
    --info-800: #185864;
    --info-900: #103B42;
    --info-950: #0A2328;
    --info-contrast: #fff;


  }
  @media (max-width: 1024px){
    .text-base{
      font-size: 1rem !important;
    }
    .text-sm{
      font-size:.8571428571428571rem !important;
    }
    .text-lg{
      font-size: 1.4285714285714286rem !important;
    }
    .text-xs{
      font-size: .7142857142857143rem !important;
    }
    .text-xl{
      font-size: 2.2857142857142856rem !important;
    }
  }

.slick-arrow:before{
      font-family: 'one-drop-proj' !important;
      content: ' \e801' !important;
}

.shadow-none{
  box-shadow: none !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;