/*Fonts*/

@font-face {
    font-family: 'Axiforma-Thin';
    src: url('../assets/fonts/Axiforma-Thin.eot');
    src: url('../assets/fonts/Axiforma-Thin.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/Axiforma-Thin.svg#Axiforma-Thin') format('svg'),
         url('../assets/fonts/Axiforma-Thin.ttf') format('truetype'),
         url('../assets/fonts/Axiforma-Thin.woff') format('woff'),
         url('../assets/fonts/Axiforma-Thin.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Axiforma-Book';
    src: url('../assets/fonts/Axiforma-Book.eot');
    src: url('../assets/fonts/Axiforma-Book.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/Axiforma-Book.svg#Axiforma-Book') format('svg'),
         url('../assets/fonts/Axiforma-Book.ttf') format('truetype'),
         url('../assets/fonts/Axiforma-Book.woff') format('woff'),
         url('../assets/fonts/Axiforma-Book.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  
  
  @font-face {
    font-family: 'Axiforma-Bold';
    src: url('../assets/fonts/Axiforma-Bold.eot');
    src: url('../assets/fonts/Axiforma-Bold.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/Axiforma-Bold.svg#Axiforma-Bold') format('svg'),
         url('../assets/fonts/Axiforma-Bold.ttf') format('truetype'),
         url('../assets/fonts/Axiforma-Bold.woff') format('woff'),
         url('../assets/fonts/Axiforma-Bold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Axiforma-Black';
    src: url('../assets/fonts/Axiforma-Black.eot');
    src: url('../assets/fonts/Axiforma-Black.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/Axiforma-Black.svg#Axiforma-Black') format('svg'),
         url('../assets/fonts/Axiforma-Black.ttf') format('truetype'),
         url('../assets/fonts/Axiforma-Black.woff') format('woff'),
         url('../assets/fonts/Axiforma-Black.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  
  
  /*Icons*/
  @font-face {
    font-family: 'one-drop-proj';
    src: url('../assets/fonts/one-drop-proj.eot?20173877');
    src: url('../assets/fonts/one-drop-proj.eot?20173877#iefix') format('embedded-opentype'),
         url('../assets/fonts/one-drop-proj.woff2?20173877') format('woff2'),
         url('../assets/fonts/one-drop-proj.woff?20173877') format('woff'),
         url('../assets/fonts/one-drop-proj.ttf?20173877') format('truetype'),
         url('../assets/fonts/one-drop-proj.svg?20173877#one-drop-proj') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  /* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
  /* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
  /*
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
      font-family: 'one-drop-proj';
      src: url('../font/one-drop-proj.svg?20173877#one-drop-proj') format('svg');
    }
  }
  */
  [class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: "one-drop-proj";
    font-style: normal;
    font-weight: normal;
    speak: never;
  
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */
  
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
  
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
  
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;
  
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
  
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  }
  
  .icon-warning-circle:before { content: '\e800'; } /* '' */
  .icon-arrow-down:before { content: '\e801'; } /* '' */
  .icon-check-circle:before { content: '\e802'; } /* '' */
  .icon-check-circle-out:before { content: '\e803'; } /* '' */
  .icon-warning-circle-out:before { content: '\e804'; } /* '' */
  .icon-eye:before { content: '\e805'; } /* '' */
  .icon-map-pin:before { content: '\e806'; } /* '' */
  .icon-previous:before { content: '\e807'; } /* '' */
  .icon-next:before { content: '\e808'; } /* '' */
  .icon-arrow:before { content: '\e809'; } /* '' */
  .icon-filter:before { content: '\e80a'; } /* '' */
  .icon-camera:before { content: '\e80b'; } /* '' */
  .icon-close:before { content: '\e80c'; } /* '' */
  .icon-search:before { content: '\e80d'; } /* '' */
  .icon-mail:before { content: '\e80e'; } /* '' */
  .icon-settings:before { content: '\e80f'; } /* '' */
  .icon-data-base:before { content: '\e810'; } /* '' */
  .icon-gestion:before { content: '\e811'; } /* '' */
  .icon-home:before { content: '\e812'; } /* '' */
  .icon-interventions:before { content: '\e813'; } /* '' */
  .icon-comunities:before { content: '\e814'; } /* '' */
  .icon-folder:before { content: '\e815'; } /* '' */
  .icon-arrow-rotate-left:before { content: '\e816'; } /* '' */
  .icon-edit:before { content: '\e817'; } /* '' */
  .icon-delete:before { content: '\e818'; } /* '' */
  .icon-arrow-rotate-right:before { content: '\e819'; } /* '' */
  .icon-view:before { content: '\e81a'; } /* '' */
  .icon-eye-off:before { content: '\e81b'; } /* '' */
  .icon-tag:before { content: '\e81c'; } /* '' */
  .icon-add-tag:before { content: '\e81d'; } /* '' */
  .icon-book:before { content: '\e81e'; } /* '' */
  .icon-assignment_ind:before { content: '\e81f'; } /* '' */
  .icon-parties:before { content: '\e820'; } /* '' */
  .icon-folder-trayec:before { content: '\e821'; } /* '' */
  .icon-recomendations:before { content: '\e822'; } /* '' */
  .icon-info-top:before { content: '\e823'; } /* '' */
  .icon-library-books:before { content: '\e824'; } /* '' */
  .icon-art:before { content: '\e825'; } /* '' */
  .icon-contacts:before { content: '\e826'; } /* '' */
  .icon-heart-empty:before { content: '\e827'; } /* '' */
  .icon-heart:before { content: '\e828'; } /* '' */
  .icon-paper-clip:before { content: '\e829'; } /* '' */
  .icon-download:before { content: '\e82a'; } /* '' */
  .icon-watch:before { content: '\e82b'; } /* '' */
  .icon-verified:before { content: '\e82c'; } /* '' */
  .icon-chat:before { content: '\e82d'; } /* '' */
  .icon-leaderboard:before { content: '\e82e'; } /* '' */
  .icon-drafts:before { content: '\e82f'; } /* '' */
  .icon-forward-inbox:before { content: '\e830'; } /* '' */
  .icon-send:before { content: '\e831'; } /* '' */
  .icon-close-circle:before { content: '\e832'; } /* '' */
  .icon-unread-email:before { content: '\e833'; } /* '' */
  .icon-reload:before { content: '\e834'; } /* '' */
